<template>
    <section class="flex flex-col gap-4">
        <MyAccountLegend>
            {{ $t("Change_Password.PageTitle") }}
        </MyAccountLegend>
        <div v-if="issueMessages['Global']" class="alert alert-danger text-center">
            {{ issueMessages["Global"] }}
        </div>
        <fieldset class="w-1/2">
            <form id="form" novalidate role="form" class="flex flex-col gap-2" @submit.stop.prevent="submit">
                <label for="OldPassword">{{ $t("Change_Password.OldPasswordLabel") }}</label>
                <input
                    id="OldPassword"
                    v-model="editedPasswordFields.OldPassword"
                    :class="{ 'border-danger': submitted && issueMessages['OldPassword'] }"
                    type="password"
                    name="OldPassword"
                    @blur="validate" />
                <span v-if="submitted" class="text-danger">{{ issueMessages["OldPassword"] }}</span>

                <label for="NewPassword">{{ $t("Change_Password.NewPasswordLabel") }}</label>
                <input
                    id="NewPassword"
                    v-model="editedPasswordFields.NewPassword"
                    :class="{ 'border-danger': issueMessages['NewPassword'] }"
                    type="password"
                    name="NewPassword"
                    @keyup="validate" />
                <PasswordStrengthMeter
                    v-if="editedPasswordFields.NewPassword.length > 0"
                    :password="editedPasswordFields.NewPassword" />
                <span class="text-danger">{{ issueMessages["NewPassword"] }}</span>

                <label for="ConfirmPassword">{{ $t("Register.ConfPasswordLabel") }}</label>
                <input
                    id="ConfirmPassword"
                    v-model="editedPasswordFields.ConfirmPassword"
                    :class="{ 'border-danger': submitted && issueMessages['ConfirmPassword'] }"
                    type="password"
                    name="ConfirmPassword"
                    @keyup="validate" />
                <span v-if="submitted" class="text-danger">{{ issueMessages["ConfirmPassword"] }}</span>

                <Button type="submit" class="self-start" variant="default">
                    {{ $t("Register.ChangePassBtn") }}
                </Button>
            </form>
        </fieldset>
    </section>
</template>

<script setup lang="ts">
import type { SafeParseResult } from "valibot";
import * as v from "valibot";
import { FetchError } from "ofetch";

type PasswordFields = {
    OldPassword: string;
    NewPassword: string;
    ConfirmPassword: string;
};

type SuccessResponseType = {
    Success: true;
};

type ErrorResponseType = {
    OldPassword?: string;
    NewPassword?: string;
    ConfirmPassword?: string;
    Global?: string;
};

type ChangePasswordResponse = SuccessResponseType | ErrorResponseType;

const { t } = useI18n();
const api = useApi();
const { toast } = useToast();

const apiErrors = ref<ChangePasswordResponse | null>(null);

const submitted = ref(false);
const editedPasswordFields = ref<PasswordFields>({
    OldPassword: "",
    NewPassword: "",
    ConfirmPassword: "",
});

const PasswordSchema = v.pipe(
    v.object({
        OldPassword: v.pipe(v.string(), v.trim(), v.nonEmpty(t("Change_Password.OldPasswordRequired"))),
        NewPassword: v.pipe(
            v.string(),
            v.trim(),
            v.nonEmpty(t("Global.PasswordRequired")),
            v.minLength(8, t("Register.PasswordStrengthCharactersError"))
        ),
        ConfirmPassword: v.pipe(v.string(), v.trim(), v.nonEmpty(t("Register.ConfirmPasswordError"))),
    }),
    v.forward(
        v.partialCheck(
            [["NewPassword"], ["ConfirmPassword"]],
            (input) => {
                return input.NewPassword === input.ConfirmPassword;
            },
            t("Register.PasswordMatchError")
        ),
        ["ConfirmPassword"]
    )
);

const parseResult = ref<SafeParseResult<typeof PasswordSchema> | null>(null);

const issueMessages = computed(() => {
    const parseIssues = parseResult.value?.issues ?? [];
    const parseErrors = Object.fromEntries(
        parseIssues?.map((i) => {
            return [v.getDotPath(i), i.message];
        })
    );

    return { ...parseErrors, ...apiErrors.value };
});

async function submit() {
    submitted.value = true;

    validate();

    if (parseResult.value?.issues?.length) {
        return;
    }

    try {
        const response = await api<SuccessResponseType>("api/account/change-password", {
            method: "POST",
            body: {
                OldPassword: editedPasswordFields.value.OldPassword,
                NewPassword: editedPasswordFields.value.NewPassword,
                Password: editedPasswordFields.value.ConfirmPassword,
            },
        });

        if (response.Success) {
            toast({ title: t("Change_Password.Success"), variant: "success" });
            editedPasswordFields.value = {
                OldPassword: "",
                NewPassword: "",
                ConfirmPassword: "",
            };
        }
    } catch (_error) {
        if (_error instanceof FetchError) {
            apiErrors.value = _error.data.Errors;
        } else {
            apiErrors.value = { Global: t("Change_Password.UpdateError") };
        }

        editedPasswordFields.value = {
            OldPassword: "",
            NewPassword: "",
            ConfirmPassword: "",
        };
    }
}

function validate() {
    apiErrors.value = null;
    parseResult.value = v.safeParse(PasswordSchema, editedPasswordFields.value);
}
</script>
