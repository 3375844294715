<template>
    <div class="relative w-full h-6 rounded-full bg-gray-50 border border-gray-200 overflow-hidden">
        <div
            class="absolute h-full text-white text-sm text-center transition-width"
            :class="passwordStrengthColor"
            role="progressbar"
            :style="{ width: passwordStrengthPercent }">
            {{ passwordStrengthDescription }}
        </div>
    </div>
</template>
<script setup lang="ts">
import zxcvbn from "zxcvbn-typescript";

const { t } = useI18n();

const props = defineProps<{ password: string }>();

const passwordStrengthDescriptions = [
    "",
    t("Register.PasswordQualityBad"),
    t("Register.PasswordQualityWeak"),
    t("Register.PasswordQualityGood"),
    t("Register.PasswordQualityStrong"),
];
const passwordColors = ["bg-danger-600", "bg-danger-600", "bg-warning-300", "bg-primary-400", "bg-success-400"];

const passwordStrength = computed(() => {
    return zxcvbn(props.password).score;
});
const passwordStrengthDescription = computed(() => {
    return passwordStrengthDescriptions[passwordStrength.value];
});
const passwordStrengthPercent = computed(() => {
    return passwordStrength.value * 25 + "%";
});
const passwordStrengthColor = computed(() => {
    return passwordColors[passwordStrength.value];
});
</script>
